/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Center, Divider, Flex, Grid, Group, Image, Modal, Paper, Text, UnstyledButton } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import {
  IconBuildingBank,
  IconDots,
  IconFileInvoice,
  IconInbox,
  IconLayoutList,
  IconLogin,
  IconMoneybag,
  IconNetwork,
  IconUsers,
  IconWallet,
} from "@tabler/icons-react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import Background from "../assets/background-blur.svg";
import BackgroundLine from "../assets/background-line.svg";
import { GridCard } from "../components/card";
import Layout from "../components/layout/layout";
import { IAuth } from "../interfaces/IAuth";
import { dateTimeFormat } from "../utils/date";
import { message } from "../utils/message";
import Bogd from "./../assets/bogd.png";
import Golomt from "./../assets/golomt.svg";
import Dehub from "./../assets/logo-core.svg";

let __WINDOW_IS_LOAD__ = false;

const HomePage = () => {
  const { classes } = useStyles();
  const { user, accessToken } = useSelector((state: { auth: IAuth }) => state.auth);
  const location = useLocation();
  const [action, setAction] = useState<any[]>([]);

  const DATA = [
    {
      module: "PARTNER",
      name: "Partner",
      description: "Партнер профайл, Buyer болон Supplier бизнесийн тохиргоо",
      fromColor: "#32CD32",
      icon: IconBuildingBank,
      url: `https://partner.dehub.mn/access?token=${accessToken}`,
      // url: `http://localhost:3001/access?token=${accessToken}`,
      logins: [],
    },
    {
      module: "NETWORK",
      name: "Network",
      description: "Бусад бизнесийг урих, урилгаар хамтрах, нөхцөлөө тохирох",
      fromColor: "#A020F0",
      icon: IconNetwork,
      url: `https://network.dehub.mn/access?token=${accessToken}`,
      // url: `http://localhost:3002/access?token=${accessToken}`,
    },
    {
      module: "INVOICE",
      name: "Invoice",
      description: "Нэхэмжлэх бичих, хүлээн авах, батлах, буцаах гэх мэт",
      fromColor: "#FFBF00",
      icon: IconFileInvoice,
      url: `https://invoice.dehub.mn/access?token=${accessToken}`,
      // url: `http://localhost:3001/access?token=${accessToken}`,
      logins: [],
    },
    {
      module: "INVENTORY",
      name: "Inventory",
      description: "Бараа, ажил үйлчилгээний бүртгэх, үнийн тохиргоо хийх",
      fromColor: "#7950F3",
      icon: IconInbox,
      url: `https://item.dehub.mn/access?token=${accessToken}`,
      // url: `http://localhost:3004/access?token=${accessToken}`,
    },
    {
      module: "USER",
      name: "User Management",
      description: "Системийн хэрэглэгч, АПП хэрэглэгчийн бүртгэл, эрх...",
      fromColor: "rgb(76, 110, 245)",
      icon: IconUsers,
      url: `https://user.dehub.mn/access?token=${accessToken}`,
      // url: `http://localhost:3001/access?token=${accessToken}`,
      logins: [],
    },
    {
      module: "PAYMENT",
      name: "Payment",
      description: "Бизнес хоорондын төлбөр тооцоо, НӨАТ гэх мэт",
      fromColor: "#00539C",
      icon: IconWallet,
      url: `https://payment.dehub.mn/access?token=${accessToken}`,
      // url: `http://localhost:3006/access?token=${accessToken}`,
      logins: [],
    },
    {
      module: "ORDER",
      name: "Order",
      description: "Авлагын эрх, ашиглалт, бизнес хоорондын тооцоо",
      fromColor: "#10A19D",
      icon: IconLayoutList,
      url: `https://order.dehub.mn/access?token=${accessToken}`,
      // url: `http://localhost:3001/access?token=${accessToken}`,
      logins: [],
    },
    {
      banks: [
        {
          isActive: false,
          name: "Golomt",
          url: `http://golomt.scf.mn/auth/login/?businessName=${user?.currentBusiness?.profileName || "unknow"}&&businessRef=${
            user?.currentBusiness?.refCode || "unknow"
          }&&partnerRef=${user?.currentBusiness?.partner?.refCode || "unknow"}&&partnerName=${
            user?.currentBusiness?.partner?.businessName || "unknow"
          }&&email=${user?.email || "unknow"}&&regNumber=${user?.currentBusiness?.partner?.regNumber || "unknow"}&&finance=financeModule`,
        },
        {
          isActive: false,
          name: "Bogd",
          url: "",
        },
      ],
      module: "FINANCE",
      name: "Finance",
      description: "Батлагдсан нэхэмжлэхэд эрт санхүүжилт авах...",
      icon: IconMoneybag,
      url: `https://dehub.scf.mn/auth/login/?businessName=${user?.currentBusiness?.profileName || "unknow"}&&businessRef=${
        user?.currentBusiness?.refCode || "unknow"
      }&&partnerRef=${user?.currentBusiness?.partner?.refCode || "unknow"}&&partnerName=${user?.currentBusiness?.partner?.businessName || "unknow"}&&email=${
        user?.email || "unknow"
      }&&regNumber=${user?.currentBusiness?.partner?.regNumber || "unknow"}&&finance=financeModule`,
      logins: [
        {
          url: `https://bogd.scf.mn/auth/login/?businessName=${user?.currentBusiness?.profileName || "unknow"}&&businessRef=${
            user?.currentBusiness?.refCode || "unknow"
          }&&partnerRef=${user?.currentBusiness?.partner?.refCode || "unknow"}&&partnerName=${
            user?.currentBusiness?.partner?.businessName || "unknow"
          }&&email=${user?.email || "unknow"}&&regNumber=${user?.currentBusiness?.partner?.regNumber || "unknow"}&&finance=financeModule`,
          code: "BOGD",
        },
        {
          url: `https://golomt.scf.mn/auth/login/?businessRef=${user?.currentBusiness?.refCode || "unknow"}&&regNumber=${
            user?.currentBusiness?.partner?.regNumber || "unknow"
          }&&finance=financeModule`,
          code: "GOLOMT",
        },
        {
          url: `https://dehub.scf.mn/auth/login/?businessName=${user?.currentBusiness?.profileName || "unknow"}&&businessRef=${
            user?.currentBusiness?.refCode || "unknow"
          }&&partnerRef=${user?.currentBusiness?.partner?.refCode || "unknow"}&&partnerName=${
            user?.currentBusiness?.partner?.businessName || "unknow"
          }&&email=${user?.email || "unknow"}&&regNumber=${user?.currentBusiness?.partner?.regNumber || "unknow"}&&finance=financeModule`,
          code: "DEHUB",
        },
      ],
    },
  ];

  React.useEffect(() => {
    let authResult = new URLSearchParams(location.search);
    let verifyConfirmType = authResult.get("verifyConfirmType");

    if (verifyConfirmType && !__WINDOW_IS_LOAD__) {
      setAction(["finance", DATA[7]]);
      if (verifyConfirmType === "confirmed-golomt") {
        message.success(`Голомт банкны бүртгэл амжилттай баталгаажлаа. Та нэвтэрнэ үү. ${dateTimeFormat(new Date())} `);
      } else if (verifyConfirmType === "confirmed-bogd") {
        message.success(`Богд банкны бүртгэл амжилттай баталгаажлаа. Та нэвтэрнэ үү. ${dateTimeFormat(new Date())} `);
      }
      __WINDOW_IS_LOAD__ = true;
    }
  }, [location]);

  React.useEffect(() => {
    if (!user && !accessToken) {
      window.location.href = "/login";
    }
  }, [accessToken, user]);

  return (
    <Layout>
      <Image className={classes.background} src={Background} />
      <Image className={classes.backgroundLine} src={BackgroundLine} />
      <div className={classes.container}>
        <div className={classes.row}>
          <div>
            <Text fz={"lg"} c={"white"}>
              Сайн байна уу!
            </Text>
            <div className={classes.text}>
              {user?.lastName ? user?.lastName[0] + "." : ""} {user?.firstName ?? "Админ"}
            </div>
            <div className={classes.description}>Бизнес хоорондын үнэ цэн нэмсэн түншлэлийн экосистемд тавтай морил!</div>
          </div>
        </div>
        <Grid gutter={20}>
          {DATA.map((data, index: number) => (
            <GridCard data={data} setAction={setAction} key={index} />
          ))}
        </Grid>
      </div>
      <Modal
        opened={action[0] === "finance"}
        onClose={() => setAction([])}
        centered
        title={false}
        size={"35rem"}
        withCloseButton={false}
        styles={{
          body: {
            padding: "2rem",
            margin: 0,
          },
          header: { background: "transparent" },
        }}>
        <div>
          <Box mb={"lg"}>
            <Center>
              <Text fz={"xl"} fw={600}>
                Санхүүжилтийн хөтөлбөрт хамрагдах банкаа сонгож нэвтэрнэ үү.
              </Text>
            </Center>
          </Box>

          <UnstyledButton
            w={"100%"}
            onClick={() => {
              window.open(`${action[1] && action[1].logins.find((item: any) => item.code === "BOGD").url}`);
              setAction([]);
            }}>
            <Paper withBorder radius={"md"}>
              <Group align="center" justify="space-between">
                <Flex align="center" gap={"lg"}>
                  <Box
                    style={{
                      borderRadius: "5px 0 0 5px",
                      background: `linear-gradient(180deg, rgba(27,128,86,1) 0%, rgba(180,229,206,1) 100%)`,
                    }}
                    m={-1}
                    h={"80px"}
                    w={"6px"}></Box>
                  <Paper>
                    <img src={Bogd} alt="" height={"35px"} />
                  </Paper>
                </Flex>
                <Box pr={"lg"}>
                  <IconLogin />
                </Box>
              </Group>
            </Paper>
          </UnstyledButton>
          <Center py={"xs"}>
            <Divider w={"120px"} mb={"5px"} />
          </Center>
          <UnstyledButton
            w={"100%"}
            p={0}
            onClick={() => {
              window.open(`${action[1] && action[1].logins.find((item: any) => item.code === "GOLOMT").url}`);
              setAction([]);
            }}>
            <Paper withBorder radius={"md"}>
              <Group align="center" justify="space-between">
                <Flex align="center" gap={"lg"}>
                  <Box
                    style={{
                      borderRadius: "5px 0 0 5px",
                      background: `linear-gradient(180deg, rgba(249,77,162,1) 0%, rgba(171,102,253,1) 46%, rgba(117,197,214,1) 100%`,
                    }}
                    m={-1}
                    h={"80px"}
                    w={"6px"}></Box>
                  <Paper>
                    <img src={Golomt} alt="" height={"35px"} />
                  </Paper>
                </Flex>
                <Box pr={"lg"}>
                  <IconLogin />
                </Box>
              </Group>
            </Paper>
          </UnstyledButton>
          <Center py={"xs"}>
            <Divider w={"120px"} mb={"5px"} />
          </Center>
          <UnstyledButton
            w={"100%"}
            p={0}
            onClick={() => {
              window.open(`${action[1] && action[1].logins.find((item: any) => item.code === "DEHUB").url}`);
              setAction([]);
            }}>
            <Paper withBorder radius={"md"}>
              <Group align="center" justify="space-between">
                <Flex align="center" gap={"lg"}>
                  <Box
                    style={{
                      borderRadius: "5px 0 0 5px",
                      background: `linear-gradient(180deg, #114289 19%, rgba(102,255,51,1) 72%, rgba(133,209,245,1) 100%)`,
                    }}
                    m={-1}
                    h={"80px"}
                    w={"6px"}></Box>
                  <Flex align="center" gap="xs">
                    <Paper>
                      <img src={Dehub} alt="" height={"35px"} />
                    </Paper>
                    <div>
                      <Text fz={"lg"} fw={700} lh={"22px"}>
                        DeHUB
                      </Text>
                      <Text ta={"end"} fz={"xs"} c="dimmed" lh={"12px"}>
                        Finance general
                      </Text>
                    </div>
                  </Flex>
                </Flex>
                <Box pr={"lg"}>
                  <IconLogin />
                </Box>
              </Group>
            </Paper>
          </UnstyledButton>
          <Center mt={"xs"}>
            <IconDots color="gray" stroke={1} />
          </Center>
        </div>
      </Modal>
    </Layout>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    maxWidth: "1600px",
    margin: "auto",
    paddingTop: 120,
    paddingBottom: 120,
    paddingLeft: 30,
    paddingRight: 30,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 30,
  },
  background: {
    position: "absolute",
    opacity: 0.5,
    bottom: 0,
    zIndex: -1,
  },
  backgroundLine: {
    position: "absolute",
    opacity: 0.1,
    bottom: 0,
    zIndex: -1,
  },
  description: {
    maxWidth: "1000px",
    fontSize: 18,
    color: "white",
  },
  text: {
    fontSize: 64,
    color: "white",
    fontWeight: 800,
    textTransform: "capitalize",
  },
}));

export default HomePage;

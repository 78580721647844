import { Button, Divider, Flex, Grid } from "@mantine/core";
import React, { useState } from "react";
import * as yup from "yup";
import { AuthApi } from "../../apis";
import { message } from "../../utils/message";
import { Form } from "../form";
import { TextField } from "../form/text-field";

const schema = yup.object({
  oldPassword: yup.string().required("Заавал бөглөнө!"),
  newPassword: yup.string().min(6, "Багадаа 6 тэмдэгттэй байх ёстой").max(16, "Ихдээ 16 тэмдэгттэй байх ёстой").required("Нууц үг оруулана уу"),
  checkPassword: yup
    .string()
    .min(6, "Багадаа 6 тэмдэгттэй байх ёстой")
    .max(16, "Ихдээ 16 тэмдэгттэй байх ёстой")
    .required("Нууц үг оруулана уу")
    .oneOf([yup.ref("newPassword")], "Нууц үг таарахгүй байна"),
});

export function ChangePasswordForm({ onClose }: { onClose: () => void }) {
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      await AuthApi.changePassword({ oldPassword: values.oldPassword, password: values.newPassword });
      setLoading(false);
      message.success("Таны хүсэлт амжилттай");
      onClose();
    } catch (error: any) {
      message.error(error?.message);
      setLoading(false);
    }
  };

  const [data] = React.useState<any>({
    oldPassword: undefined,
    newPassword: undefined,
    checkPassword: undefined,
  });

  return (
    <Form initialValues={data} validationSchema={schema} onSubmit={onSubmit}>
      {() => {
        return (
          <Grid>
            <Grid.Col>
              <TextField name="oldPassword" label="Хуучин нууц үг" placeholder="Хуучин нууц үг" />
            </Grid.Col>
            <Grid.Col>
              <TextField name="newPassword" label="Шинэ нууц үг" placeholder="Шинэ нууц үг" />
            </Grid.Col>
            <Grid.Col>
              <TextField name="checkPassword" label="Давтан нууц үг" placeholder="Давтан нууц үг" />
            </Grid.Col>
            <Grid.Col>
              <Divider mb={"xs"} />
              <Flex justify="space-between" align="center" gap="xs">
                <Button variant="outline" color="gray" onClick={onClose}>
                  Болих
                </Button>
                <Button loading={loading} variant="outline" type="submit">
                  Хадгалах
                </Button>
              </Flex>
            </Grid.Col>
          </Grid>
        );
      }}
    </Form>
  );
}

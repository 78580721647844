import { DatePickerInput, DateValue } from "@mantine/dates";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder: string;
  icon?: any;
  nullable?: boolean;
  styles?: any;
  noError?: boolean;
  onChange?: any;
  maxDate?: Date | undefined;
  minDate?: Date | undefined;
};

export function DatePickerField({
  name,
  label,
  placeholder,
  disabled = false,
  icon,
  nullable,
  styles,
  noError = false,
  onChange: onChangeValue,
  minDate,
  maxDate,
}: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <>
      <DatePickerInput
        maxDate={maxDate}
        minDate={minDate}
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        valueFormat="MM-DD-YYYY"
        error={error}
        clearable={false}
        rightSection={icon}
        value={value ? new Date(value) : null}
        onChange={(e: DateValue) => {
          if (e) {
            const date = new Date(e);
            onChange(date);
            onChangeValue && onChangeValue(date);
          } else {
            onChangeValue && onChangeValue(null);
          }
        }}
        required={nullable ? false : true}
        styles={{
          error: {
            display: noError ? "none" : "block",
          },
          cell: {
            padding: "0px !important",
          },
          ...styles,
        }}
      />
    </>
  );
}

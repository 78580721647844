import { Image } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import Background from "../../assets/background-blur.svg";
import { OtpVerifyForm } from "../../components/auth/otp-verify";
import Layout from "../../components/layout/layout";

export function RegistrationEmailVerify() {
  const { classes } = useStyles();

  return (
    <Layout>
      <Image className={classes.background} src={Background} />

      <div className={classes.container}>
        <OtpVerifyForm type="create" />
      </div>
    </Layout>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    maxWidth: "1600px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    paddingTop: 80,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 80,
    paddingLeft: 30,
    paddingRight: 30,
  },
  background: {
    position: "absolute",
    height: "100vh",
    opacity: 0.8,
    bottom: 0,
    zIndex: -1,
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    height: 420,
    width: "100%",
    maxWidth: 624,
    borderRadius: 10,
    gap: 10,
    position: "relative",
  },
}));

import { createSlice } from "@reduxjs/toolkit";
import { IGeneral } from "../interfaces/IGeneral";

const initialState: IGeneral = {
  districts: [],
  equityTypes: [],
  khoroos: [],
  legalEntityTypes: [],
  partnerCategories: [],
  provinces: [],
  productCategories: [],
  serviceCategories: [],
  branchTypes: [],
  businesses: [],
  warehouseStatus: [],
  partnerStaffs: [],
  departmentUnits: [],
  employeeUnits: [],
  roles: [],
  businessSectors: [],
  partnerClassifications: [],
  socialTypes: [],
};

const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    initGeneral: (state, { payload }: { payload: IGeneral }) => {
      const {
        districts,
        equityTypes,
        khoroos,
        legalEntityTypes,
        partnerCategories,
        provinces,

        productCategories,
        serviceCategories,
        branchTypes,
        businesses,
        businessSectors,
        partnerStaffs,
        departmentUnits,
        employeeUnits,
        roles,
        warehouseStatus,
        partnerClassifications,

        socialTypes,
      } = payload;

      state.districts = districts;
      state.productCategories = productCategories;
      state.serviceCategories = serviceCategories;
      state.equityTypes = equityTypes;
      state.khoroos = khoroos;
      state.partnerStaffs = partnerStaffs;
      state.warehouseStatus = warehouseStatus;
      state.businesses = businesses;
      state.legalEntityTypes = legalEntityTypes;
      state.partnerCategories = partnerCategories;
      state.branchTypes = branchTypes;
      state.provinces = provinces;
      state.businessSectors = businessSectors;
      state.departmentUnits = departmentUnits;
      state.employeeUnits = employeeUnits;
      state.partnerClassifications = partnerClassifications;
      state.roles = roles;

      state.socialTypes = socialTypes;

      // console.log("==============", payload);

      return state;
    },

    updateBusinessSectors: (state, { payload }: { payload: any }) => {
      state.businessSectors = payload;

      return state;
    },
  },
});

export const generalReducer = generalSlice.reducer;

export const { initGeneral, updateBusinessSectors } = generalSlice.actions;

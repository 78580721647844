import { Button, Grid, Group, LoadingOverlay } from "@mantine/core";
import React from "react";
import * as yup from "yup";
import { AuthApi } from "../../apis";
import { message } from "../../utils/message";
import { Form } from "../form";
import { TextField } from "../form/text-field";

const schema = (isEdit: boolean) =>
  isEdit
    ? yup.object({
        oldPin: yup.string().required("Заавал бөглөнө!"),
        newPin: yup
          .string()
          .matches(/^[0-9]{6}$/, "Пин код 6 оронтой тоо байна!")
          .required("Заавал бөглөнө!"),
        checkPin: yup
          .string()
          .required("Нууц үг оруулана уу")
          .oneOf([yup.ref("newPin")], "Нууц үг таарахгүй байна!"),
      })
    : yup.object({
        newPin: yup
          .string()
          .matches(/^[0-9]{6}$/, "Пин код 6 оронтой тоо байна!")
          .required("Заавал бөглөнө!"),
        checkPin: yup
          .string()
          .required("Заавал бөглөнө!")
          .oneOf([yup.ref("newPin")], "Нууц үг таарахгүй байна!"),
      });

export function ChangePinForm({ onClose, isEdit }: { onClose: () => void; isEdit: boolean }) {
  const [loading, setLoading] = React.useState<boolean>(false);

  const onSubmit = async (values: any) => {
    setLoading(true);

    try {
      if (isEdit) {
        await AuthApi.updatePin({ oldPin: values.oldPin, pin: values.newPin });
      } else {
        await AuthApi.createPin({ pin: values.newPin });
      }
      setLoading(false);
      message.success("Таны хүсэлт амжилттай");
      onClose();
    } catch (error: any) {
      message.error(error?.message);
      setLoading(false);
    }
  };

  const [data] = React.useState<any>({
    oldPin: undefined,
    newPin: undefined,
    checkPin: undefined,
  });

  return (
    <Form initialValues={data} validationSchema={schema(isEdit)} onSubmit={onSubmit}>
      {() => {
        return (
          <Grid sx={{ position: "relative" }}>
            <LoadingOverlay visible={loading} />

            {isEdit ? (
              <Grid.Col>
                <TextField name="oldPin" label="Хуучин пин код" placeholder="Хуучин пин код" />
              </Grid.Col>
            ) : null}
            <Grid.Col>
              <TextField name="newPin" label="Шинэ пин код" placeholder="Шинэ пин код" />
            </Grid.Col>
            <Grid.Col>
              <TextField name="checkPin" label="Давтан пин код" placeholder="Давтан пин код" />
            </Grid.Col>
            <Grid.Col>
              <Group align="right">
                <Button variant="outline" type="submit">
                  {isEdit ? " ПИН Код солих" : " ПИН Код үүсгэх"}
                </Button>
              </Group>
            </Grid.Col>
          </Grid>
        );
      }}
    </Form>
  );
}

import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const uploadImage = async (data: any) => {
  const type = "2fa";
  return httpRequest.upload(`/mdi/media/file/${type}/upload`, data);
};

export const uploadFile = async (data: any) => {
  return httpRequest.get("/2fa/product_category", data);
};

export const get = async (id: string, name: string) => {
  return httpRequest.get(`/p/${id}/${name}`);
};

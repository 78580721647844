import { createStyles } from "@mantine/emotion";
import Header from "./header";
import { AppShell } from "@mantine/core";

export const Layout = ({ children }: { children: any }) => {
  const { classes } = useStyles();

  return (
    <AppShell className={classes.container}>
      <div className={classes.body}>
        <Header />
        <div className={classes.child}>{children}</div>
      </div>
      <div className={classes.footerText}>© {new Date().getFullYear()} Дижитал Эра Пэймэнт</div>
    </AppShell>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  child: {
    position: "relative",
  },
  body: {
    flex: 1,
  },
  footerText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 30,
    fontSize: 20,
    color: "white",
  },
}));

export default Layout;

import { MantineTheme } from "@mantine/core";
import { Global } from "@mantine/emotion";

export const GlobalStyle = () => {
  return (
    <Global
      styles={() => ({
        body: {
          backgroundColor: "#010614",
        },
        a: {
          color: "inherit",
          textDecoration: "inherit",
        },
      })}
    />
  );
};

export const themeConfig = {
  primaryColor: "indigo",
  components: {
    Breadcrumbs: {
      styles: (theme: MantineTheme) => ({
        breadcrumb: {
          color: theme.colors.gray[7],
          fontSize: 15,
        },
      }),
    },
    Table: {
      styles: () => ({
        highlightOnHover: {
          "&:hover": {
            backgroundColor: "red",
          },
        },
      }),
    },
  },
};

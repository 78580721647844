import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

type IOtp = {
  otpMethod: string;
};

export const get = async (data: IOtp) => {
  const res = await httpRequest.get("/2fa/auth/otp", data);
  return res;
};

export const verify = async (data: { otpMethod: string; otpCode: string | number }) => {
  const res = await httpRequest.post(`/2fa/auth/otp/verify`, data);
  return res;
};

export const phoneCheck = async (data: { otpMethod: string; phone: string }) => {
  const res = await httpRequest.get(`/2fa/auth/otp/phone_check`, data);
  return res;
};

export const registerVerify = async (data: { verifyId: string; otpCode: string }) => {
  const res = await httpRequest.post(`/2fa/auth/otp/register_verify`, data);
  return res;
};

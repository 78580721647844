import { store } from "../store";
import { authChange } from "../store/auth";
import { HttpHandler } from "./http-handler";
import { HttpRequest as BaseHttpRequest } from "./http-request";

export class HttpRequest extends BaseHttpRequest {
  store = store;
  errorHandler = (statusCode: number, error: HttpHandler): HttpHandler => {
    if (statusCode === 401) {
      store.dispatch(authChange());
    }

    throw error;
  };
}

/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import Background from "../../assets/background-blur.svg";
import BackgroundLine from "../../assets/background-line.svg";
import Golomt from "./../../assets/golomt.svg";
import BogdWhite from "./../../assets/logo hk.png";

export function LoginsPage() {
  const { classes } = useStyle();

  return (
    <div className={classes.container}>
      <div className={classes.header}>test</div>
      <img className={classes.background} src={Background} alt="" />
      <img className={classes.backgroundLine} src={BackgroundLine} alt="" />
      <div>
        <Flex align="center" justify="center" gap={"lg"}>
          <Box className={classes.card}>
            <div className={classes.logo}>
              <img src={BogdWhite} style={{ width: "auto", height: "60px", objectFit: "cover" }} alt="" />
            </div>
          </Box>
          <Box className={classes.card}>
            <div className={classes.logo}>
              <img src={Golomt} style={{ width: "auto", height: "60px", objectFit: "cover" }} alt="" />
            </div>
          </Box>
        </Flex>
      </div>
    </div>
  );
}

const useStyle = createStyles({
  logo: {
    border: "1px solid red",
  },
  header: {
    width: "100%",
    height: "82px",
    background: "#000000b0",
  },
  container: {
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    position: "relative",
  },
  backgroundLine: {
    height: "105%",
    opacity: 0.2,
    zIndex: 1,
    position: "absolute",
    top: -540,
    alignSelf: "center",
    width: "100%",
  },
  background: {
    top: -440,
    position: "absolute",
    height: "100%",
    width: "100%",
    opacity: 0.7,
    zIndex: 1,
    alignSelf: "center",
  },
  card: {
    padding: "30px",
    zIndex: 1,
    backgroundColor: "#0000007a",
    width: "100%",
    maxHeight: "400px",
    maxWidth: "400px",
  },
});

import { Image, LoadingOverlay } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import React from "react";
import Background from "../../assets/background-blur.svg";
import BackgroundLine from "../../assets/background-line.svg";
import BackgroundPattern from "../../assets/bg-pattern.png";
import LoginImage from "../../assets/login-svg.svg";
import ForgotForm from "../../components/forgotForm";
import Layout from "../../components/layout/layout";

const ForgotPassword = () => {
  const { classes } = useStyles();
  const [loading, setLoading] = React.useState<boolean>(false);

  return (
    <Layout>
      <Image className={classes.background} src={Background} />
      <Image className={classes.backgroundLine} src={BackgroundLine} />
      <div className={classes.container}>
        <div className={classes.image}>
          <img src={LoginImage} alt={"svg"} />
        </div>
        <div className={classes.card}>
          <LoadingOverlay visible={loading} />
          <img className={classes.bgBlur} src={BackgroundPattern} alt="" />
          <div className={classes.content}>
            <div>
              <div className={classes.loginText}>НУУЦ ҮГ СЭРГЭЭХ</div>
              <ForgotForm setLoading={setLoading} />
            </div>
            <div className={classes.info}>Баталгаажуулалт хийх заавартай танилцах</div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    gap: 15,
    height: "100%",
    maxWidth: "1600px",
    margin: "auto",
    paddingTop: 150,
  },
  card: {
    position: "relative",
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#ffffff09",
  },
  content: {
    zIndex: 1,
    display: "flex",
    paddingInline: 40,
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    width: "100%",
    paddingTop: 35,
    paddingBottom: 35,
  },
  bgBlur: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  image: {
    height: "800px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    color: theme.white,
    fontSize: 16,
    fontWeight: 400,
  },
  loginText: {
    marginTop: 15,
    fontSize: 40,
    color: theme.white,
    fontWeight: 800,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
  background: {
    position: "absolute",
    opacity: 0.5,
    bottom: 0,
  },
  backgroundLine: {
    position: "absolute",
    opacity: 0.1,
    bottom: 0,
  },
  info: {
    flex: 1,
    display: "flex",
    alignItems: "end",
    justifyContent: "center",
    color: "white",
  },
}));

export default ForgotPassword;
